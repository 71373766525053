<template>
  <div
    v-if="status !== 'ready'"
    class="inline-block text-xs px-3 py-1 rounded-full uppercase text-white bg-orange-500 font-bold mr-2"
    :class="{
      'bg-purple-500' : status === 'processing',
      'bg-gray-800': status === 'pending',
      'bg-blue-500': status === 'review',
      'bg-red-500': status === 'error',
    }"
  >
    <fa-icon
      v-if="status === 'processing'"
      icon="spinner"
      spin
      class="text-white text-sm mr-1"
    />
    <fa-icon
      v-else-if="status === 'error'"
      icon="cross"
      class="text-white text-sm mr-1"
    />
    <fa-icon
      v-else-if="status === 'review'"
      icon="check"
      class="text-white text-sm mr-1"
    />
    {{ status }}
  </div>
</template>
<script>
export default {
  name: 'ContentStatusBadge',

  props: ['status']
}
</script>
